import './estilo.css';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';

export function CampoInput({ cpf }) {

    const [dados, setDados] = useState();

    return (
        <div className="input-area">
            <InputMask
                mask="999.999.999-99"
                maskChar=""
                value={cpf}
                disabled
            >
                {(inputProps) => (
                    <input
                        disabled
                        type="text"
                        name="cpf"
                        ref={cpf}
                        placeholder="CPF"
                        {...inputProps}
                    />
                )}
            </InputMask>
        </div>
    );
};

export function CampoInputNome({ nome }) {
    return (
        <div className="input-area"><input disabled value={nome}></input></div>
    );
}

export function CampoInputRepresentante({ representante }) {
    return (
        <div className="input-area"><input disabled value={representante}></input></div>
    );
}

export function CampoInputLoja({ loja, setLoja, identify, url, setDados }) {

    const [nomeLoja, setNomeLoja] = useState('');
    
    useEffect(() => {
        if (identify) { // Verifica se loja está definido
            axios.get(`${url}/empresa/valida-registro-indentificador/${identify}`, {
            }).then((response) => {
                console.log(response.data);
                setDados(response.data);
                setNomeLoja(response.data.nome); // Define o nome recebido nos dados
            }).catch((error) => {
                console.error('Erro ao buscar dados:', error);
            });
        } else {
            console.log("Sem loja");
        }
    }, [loja, identify]);

    return (
        <div className="input-area">
            <input
                disabled={true}
                value={nomeLoja}
                onChange={(e) => setLoja(e.target.value)}
                placeholder="Loja"
            />
        </div>
    );
}