import React from 'react';
import './estilo.css';
import Webcam from "react-webcam";
import moment from 'moment';

function WebcamContainer({ webcamRef, registroEntrada }) {
    // Verifica se registroEntrada não é nulo e se dataHoraEntrada está definido
    const dataHoraEntradaFormatted = registroEntrada && registroEntrada.dataHoraEntrada 
        ? moment(registroEntrada.dataHoraEntrada).utcOffset(-4).format('DD/MM/YYYY HH:mm:ss')
        : '';
        
    return (
        <div className='webcam-container'>
            <div className='webcam'>
                <Webcam
                    className="webcam-image"
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                />
            </div>
            {registroEntrada && (
                <div className="registro-info">
                    <h2>Registro de Entrada</h2>
                    {/* Renderiza a data e hora formatada apenas se estiver disponível */}
                    {dataHoraEntradaFormatted && (
                        <p>Data e Hora: <span className="data-hora">{dataHoraEntradaFormatted}</span></p>
                    )}
                    <p>Promotor: <span className="data-hora">{registroEntrada.nomePromotor}</span></p>
                    <p>Loja: <span className="data-hora">{registroEntrada.nomeEmpresa}</span></p>
                    {/* Adicione mais informações do registro aqui, se necessário */}
                </div>
            )}
        </div>
    )
}

export default WebcamContainer;
