import React from 'react';
import Swal from 'sweetalert2';
import './estilo.css';

function ValidaCpf({ onQRCodeData, setCpfFromQRCode, setNome, setRepresentante, setPromotorId }) {

    const url = 'https://comepi-api-acl.comepilabs.com.br/api/rac';
    //const url = 'http://localhost:3001/api/rac';

    const ButtonValidaCpf2 = async (e) => {
        e.preventDefault();

        // Verifica se o botão de validação de CPF foi o elemento alvo do evento
        if (e.target.id === 'btnValidarCPF') {
            const { value: cpf } = await Swal.fire({
                title: 'Acessar com CPF',
                input: 'text',
                inputLabel: 'Insira o CPF',
                inputPlaceholder: '000.000.000-00', // Formato de exemplo
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'Por favor, insira um CPF válido';
                    }
                }
            });

            if (cpf) {

                try {
                    const response = await fetch(`${url}/promotor/validar-cpf/${cpf}`);
                    const data = await response.json();

                    if (data && data.cpfCnpj === cpf) {
                        Swal.fire('CPF validado!', '', 'success');

                        onQRCodeData(data);
                        // Chama a função passada por propriedade com os dados do QR code
                        // Pega o valor de CPF da consulta no Banco de Dados
                        const cpf = data.cpfCnpj;
                        setCpfFromQRCode(cpf);
                        //Pega o valor de Nome da consulta no Banco de Dados
                        const nome = data.nome;
                        setNome(nome);
                        const promotorId = data.promotorId;
                        setPromotorId(promotorId);
                        const representante = data.nomeRepresentante;
                        setRepresentante(representante);
                    } else {
                        Swal.fire('CPF inválido!', '', 'error');
                    }

                } catch (error) {
                    console.error('Erro ao validar CPF:', error);
                    Swal.fire('Erro ao validar CPF', '', 'error');
                }
            }
        }
    };

    return (
        <div>
            {/* Botão para abrir a validação de CPF */}
            <button id="btnValidarCPF" onClick={ButtonValidaCpf2}>Validar CPF</button>
            {/* Componente de validação de CPF */}
            {/* Você pode ajustar onde e como exibir o componente */}
        </div>
    );
}

export default ValidaCpf;