import React, { useState } from "react";
import './estilo.css';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import ErrorAlert from '../Alert/index.js';

function QrCodeLeitor({ onQRCodeData, setCpfFromQRCode, setNome, setRepresentante, setPromotorId }) {

    const url = 'https://comepi-api-acl.comepilabs.com.br/api/rac';
    //const url = 'http://localhost:3001/api/rac';

    const [qrCodeData, setQRCodeData] = useState('');
    const [error, setError] = useState(null);
    const [qrError, setQRError] = useState(null); // Adicione um novo estado para armazenar erros do leitor QR
    const [data, setData] = useState('No result');

    const handleScan = (data) => {

        if (data) {
            //const parsedDataCpf = JSON.parse(data.text);
            var obj = JSON.parse(data.text);
            const validaCpf = obj.cpf;

            const urlParams = new URLSearchParams(window.location.search);
            const lojaParam = urlParams.get('loja');

            if (validaCpf) {

                axios({
                    method: 'get',
                    url: `${url}/promotor/validar-cpf/` + validaCpf,
                })
                    .then(function (response) {
                        
                        if (response.data.promotorId > 0) {
                                
                                setQRCodeData(data);
                                onQRCodeData(data); 
                                // Chama a função passada por propriedade com os dados do QR code
                                // Pega o valor de CPF da consulta no Banco de Dados
                                const parsedData = JSON.parse(data.text);
                                const cpf = parsedData.cpf;
                                setCpfFromQRCode(cpf);
                                //Pega o valor de Nome da consulta no Banco de Dados
                                const nome = response.data.nome;
                                setNome(nome);
                                const promotorId = response.data.promotorId;
                                setPromotorId(promotorId);
                                const representante = response.data.nomeRepresentante;
                                setRepresentante(representante);

                        } else {
                            //alert("CPF não existe no banco de dados");
                            setError("CPF não existe no banco de dados.");
                            // Fecha o alerta automaticamente após 5 segundos
                            setTimeout(() => {
                                setError(null);
                            }, 3000);
                        }
                    });

            } else {
                //alert("CPF não está registrado no QR Code");
                setError("CPF não está registrado no QR Code.");
                // Fecha o alerta automaticamente após 5 segundos
                setTimeout(() => {
                    setError(null);
                }, 3000);
            }
        }
    }

    const handleError = (error) => {
        console.error(error);
        setQRError(error); // Armazene o erro no estado
    }

    return (
        <div>
            <div>
                {error && <ErrorAlert message={error} />} {/* Renderize o ErrorAlert se houver um erro */}
            </div>
            <div className='qrCode'>
                <QrReader
                    className="qrCode-image"
                    delay={300}
                    onError={handleError}
                    onResult={handleScan}
                />
            </div>
        </div>
    );
};

export default QrCodeLeitor;